import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import CopyBlock from "../../components/Copy/CopyBlock"
import InfoBar from '../../components/Utilities/InfoBar'

const AllGoalsIncludingYouth = ({data}) => (
  <Layout>
      <SEO
        title={"All Career Goals Including Youth Goals - Messi vs Ronaldo"}
        description={`All official goals scored by Messi and Ronaldo, including official youth competitions, such as Barcelona B and Sporting B, and international youth teams.`}
        canonicalPath={`/detailed-stats/all-goals-including-youth/`}
      />

      <h1>
      All Goals Including Youth <span className="sr-only">- Messi vs Ronaldo</span>
      </h1>

      

      <CompetitionBlock competition="All Goals Including Youth" type="all" mdata={data.allSheetMessiAllTimeStats.edges} rdata={data.allSheetRonaldoAllTimeStats.edges} subtitle="Official Senior + Youth Goals" modal={false} />

      <CopyBlock>
          <h2>Messi & Ronaldo All Time Official Goals</h2>

          <InfoBar />

          <p>The most widely accepted definition of all time career statistics are those recorded in official senior games - basically all games at senior level except club friendlies.</p>

          <p>However, Messi and Ronaldo also scored many goals at youth level, which are sometimes included in certain lists/records.</p>

          <h3>All Messi Goals Including Youth</h3>
          <table>
            <tr>
              <th>Team</th>
              <th>Goals</th>
              <th>Matches</th>
            </tr>
            <tr>
              <td>All Senior Club</td>
              <td>{data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Time Club").map(d => d.node.goals)}</td>
              <td>{data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Time Club").map(d => d.node.apps)}</td>
            </tr>
            <tr>
              <td>Argentina</td>
              <td>{data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Time Internationals").map(d => d.node.goals)}</td>
              <td>{data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Time Internationals").map(d => d.node.apps)}</td>
            </tr>
            <tr>
              <td>Barcelona B</td>
              <td>6</td>
              <td>22</td>
            </tr>
            <tr>
              <td>Barcelona C</td>
              <td>5</td>
              <td>10</td>
            </tr>
            <tr>
              <td>Argentina U23</td>
              <td>2</td>
              <td>5</td>
            </tr>
            <tr>
              <td>Argentina U20</td>
              <td>14</td>
              <td>18</td>
            </tr>
            <tr>
              <td><strong className="text-messi">Total</strong></td>
              <td><strong className="text-messi">{data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Goals Including Youth").map(d => d.node.goals)}</strong></td>
              <td><strong className="text-messi">{data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Goals Including Youth").map(d => d.node.apps)}</strong></td>
            </tr>
          </table>

          <h3>All Ronaldo Goals Including Youth</h3>
          <table>
            <tr>
              <th>Team</th>
              <th>Goals</th>
              <th>Matches</th>
            </tr>
            <tr>
              <td>All Senior Club</td>
              <td>{data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Time Club").map(d => d.node.goals)}</td>
              <td>{data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Time Club").map(d => d.node.apps)}</td>
            </tr>
            <tr>
              <td>Portugal</td>
              <td>{data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Time Internationals").map(d => d.node.goals)}</td>
              <td>{data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Time Internationals").map(d => d.node.apps)}</td>
            </tr>
            <tr>
              <td>Sporting CP B</td>
              <td>0</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Portugal U23</td>
              <td>2</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Portugal U21</td>
              <td>3</td>
              <td>10</td>
            </tr>
            <tr>
              <td>Portugal U20</td>
              <td>1</td>
              <td>5</td>
            </tr>
            <tr>
              <td>Portugal U17</td>
              <td>5</td>
              <td>7</td>
            </tr>
            <tr>
              <td>Portugal U15</td>
              <td>7</td>
              <td>9</td>
            </tr>
            <tr>
              <td><strong className="text-ronaldo">Total</strong></td>
              <td><strong className="text-ronaldo">{data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Goals Including Youth").map(d => d.node.goals)}</strong></td>
              <td><strong className="text-ronaldo">{data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Goals Including Youth").map(d => d.node.apps)}</strong></td>
            </tr>
          </table>

          <h3>All Time Top Goalscorers</h3>

          <p>Unfortunately, there isn't a crystal clear list of the top goalscorers of all time because the records and stats of players from decades past are patchy and incomplete, in addition to varying definitions of which goals should count.</p>

          <p>The two most frequently shared all time top scorer lists are courtesy of <strong className="text-highlight">IFFHS</strong> (International Federation of Football History & Statistics) and <strong className="text-highlight">RSSSF</strong> (Rec.Sport.Soccer Statistics Foundation).</p>

          <h3>IFFHS All Time Top Goalscorers List</h3>

          <p>The IFFHS list takes into account only goals scored in "top-level competitions", which discounts youth goals and goals scored in regional competitions by players such as Josef Bican.</p>

          <table>
            <tr>
              <th>Rank</th>
              <th>Player</th>
              <th>Goals</th>
              <th>Years</th>
            </tr>
            <tr>
              <td><strong className="text-ronaldo">1</strong></td>
              <td><strong className="text-ronaldo">Cristiano Ronaldo</strong></td>
              <td><strong className="text-ronaldo">{data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Goals Including Youth").map(d => d.node.goals) - 18}</strong></td>
              <td><strong className="text-ronaldo">2002-present</strong></td>
            </tr>
            <tr>
              <td><strong className="text-messi">2</strong></td>
              <td><strong className="text-messi">Lionel Messi</strong></td>
              <td><strong className="text-messi">{data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Goals Including Youth").map(d => d.node.goals) - 27}</strong></td>
              <td><strong className="text-messi">2004-present</strong></td>
            </tr>
            <tr>
              <td>3</td>
              <td>Pelé</td>
              <td>762</td>
              <td>1957-1977</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Romário</td>
              <td>755</td>
              <td>1985-2007</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Ferenc Puskás</td>
              <td>729</td>
              <td>1943-1966</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Josef Bican</td>
              <td>720</td>
              <td>1931-1955</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Jimmy Jones</td>
              <td>647</td>
              <td>1947-1964</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Gerd Müller</td>
              <td>634</td>
              <td>1964-1981</td>
            </tr>
            <tr>
              <td>9</td>
              <td>Eusébio</td>
              <td>619</td>
              <td>1958-1981</td>
            </tr>
            <tr>
              <td>10</td>
              <td>Joe Bambrick</td>
              <td>616</td>
              <td>1926-1943</td>
            </tr>
          </table>

          <h3>RSSSF All Time Top Goalscorers List</h3>

          <p>The RSSSF list takes into account goals scored in competitions at all levels. The <strong>+</strong> indicates that some matches are unaccounted for and so the number of goals/matches could be higher.</p>

          <table>
            <tr>
              <th>Rank</th>
              <th>Player</th>
              <th>Goals</th>
              <th>Matches</th>
              <th>Ratio</th>
              <th>Years</th>
            </tr>
            <tr>
              <td>1</td>
              <td>Erwin Helmchen</td>
              <td>987+</td>
              <td>577</td>
              <td>1.71</td>
              <td>1924-1951</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Josef Bican</td>
              <td>950+</td>
              <td>624</td>
              <td>1.52</td>
              <td>1931-1957</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Ronnie Rooke</td>
              <td>931+</td>
              <td>1028</td>
              <td>0.91</td>
              <td>1930-1961</td>
            </tr>
            <tr>
              <td><strong className="text-ronaldo">4</strong></td>
              <td><strong className="text-ronaldo">Cristiano Ronaldo</strong></td>
              <td><strong className="text-ronaldo">{data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Goals Including Youth").map(d => d.node.goals)}</strong></td>
              <td><strong className="text-ronaldo">{data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Goals Including Youth").map(d => d.node.apps)}</strong></td>
              <td><strong className="text-ronaldo">{parseFloat(data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Goals Including Youth").map(d => d.node.goalsPerGame)).toFixed(2)}</strong></td>
              <td>2002-present</td>
            </tr>
            <tr>
              <td><strong className="text-messi">6</strong></td>
              <td><strong className="text-messi">Lionel Messi</strong></td>
              <td><strong className="text-messi">{data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Goals Including Youth").map(d => d.node.goals)}</strong></td>
              <td><strong className="text-messi">{data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Goals Including Youth").map(d => d.node.apps)}</strong></td>
              <td><strong className="text-messi">{parseFloat(data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Goals Including Youth").map(d => d.node.goalsPerGame)).toFixed(2)}</strong></td>
              <td>2004-present</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Jimmy Jones</td>
              <td>833+</td>
              <td>751</td>
              <td>1.11</td>
              <td>1944-1965</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Ferenc Puskás</td>
              <td>806</td>
              <td>793</td>
              <td>1.02</td>
              <td>1943-1967</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Ferenc Deák</td>
              <td>795+</td>
              <td>511+</td>
              <td>1.56</td>
              <td>1939-1959</td>
            </tr>
            <tr>
              <td>9</td>
              <td>Romário</td>
              <td>784</td>
              <td>1002</td>
              <td>0.78</td>
              <td>1984-2009</td>
            </tr>
            <tr>
              <td>10</td>
              <td>Abe Lenstra</td>
              <td>783+</td>
              <td>843+</td>
              <td>0.93</td>
              <td>1936-1963</td>
            </tr>
            {/* <tr>
              <td>9</td>
              <td>Pelé</td>
              <td>775</td>
              <td>840</td>
              <td>0.92</td>
              <td>1956-1977</td>
            </tr> */}
          </table>

          <p>If you're looking for more all time goals stats, tweet us at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, and we’ll do our best to help.</p>

        </CopyBlock>

      <LastUpdated type="Stats" />
      
    </Layout>
)
export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/all-youth/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          goalsPerGame
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/all-youth/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          goalsPerGame
        }
      }
    }
  }
`

export default AllGoalsIncludingYouth
